import { computed, hasInjectionContext, inject } from "vue";

import { getShownPropertyWithConfigList } from "~/common/properties";
import {
  DARTBOARD_CELL_EDITOR,
  DUID_CELL_EDITOR,
  NEW_TASK_HEADER_RENDERER,
  ORDER_CELL_EDITOR,
  STANDARD_HEADER_RENDERER,
  TITLE_CELL_EDITOR,
} from "~/constants/injection";
import { DartboardKind, EditorMode, PageKind, PropertyKind, ViewKind } from "~/shared/enums";
import type { ExtendedColDef, Task } from "~/shared/types";
import type { DataStore } from "~/stores/DataStore";
import { getPageDisplayName, getText } from "~/utils/common";
import { makeDartboardComparator, makePropertyComparator, stringComparator } from "~/utils/comparator";

const columns = (dataStore: DataStore) => {
  const appStore = dataStore.$useAppStore();
  const pageStore = dataStore.$usePageStore();

  const canInject = hasInjectionContext();

  const DartboardCellEditor = canInject ? inject(DARTBOARD_CELL_EDITOR) : undefined;
  const DuidCellEditor = canInject ? inject(DUID_CELL_EDITOR) : undefined;
  const OrderCellEditor = canInject ? inject(ORDER_CELL_EDITOR) : undefined;
  const TitleCellEditor = canInject ? inject(TITLE_CELL_EDITOR) : undefined;
  const NewTaskHeaderRenderer = canInject ? inject(NEW_TASK_HEADER_RENDERER) : undefined;
  const StandardHeaderRenderer = canInject ? inject(STANDARD_HEADER_RENDERER) : undefined;

  const properties = computed(() =>
    getShownPropertyWithConfigList().map(([property, config]) => ({
      property,
      config,
      values: config.listColumns(property),
    }))
  );

  const defaultColDef: ExtendedColDef = {
    headerComponent: StandardHeaderRenderer,
    cellStyle: {
      fontSize: "14px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textOverflow: "clip",
    },
  };

  const isAnyView = computed(() => appStore.currentPage?.pageKind === PageKind.VIEW);

  const titleHeaderComponent = computed(() => {
    if (appStore.currentPage?.pageKind === PageKind.VIEW || appStore.currentPage?.kind === DartboardKind.FINISHED) {
      return StandardHeaderRenderer;
    }
    return NewTaskHeaderRenderer;
  });

  const titleColDef = computed<ExtendedColDef<string>>(() => {
    const property = dataStore.defaultTitleProperty;
    return {
      field: property.duid as keyof Task,
      headerName: "Title",
      headerComponentParams: {
        property,
      },
      headerComponent: titleHeaderComponent.value,
      cellRenderer: TitleCellEditor,
      cellRendererParams: {
        editorMode: EditorMode.LIST,
      },
      suppressMovable: true,
      lockPosition: "left",
      rowDrag: pageStore.hasTouch,
      sortable: true,
      comparatorFn: (a, b) => stringComparator(a.toLowerCase(), b.toLowerCase()),
      suppressHeaderMenuButton: true,
      suppressKeyboardEvent: () => true,
      flex: 1,
      minWidth: 400,
      // Return $duid $title $description
      getSearchValue: (value, task) => `${task.duid} ${value} ${getText(task.description)}`,
    };
  });

  const columnDefs = computed(() => {
    const dartboardProperty = dataStore.defaultDartboardProperty;
    const dartboardHeaderName = appStore.currentPage?.kind === ViewKind.TRASH ? "Former dartboard" : "Dartboard";
    const res: ExtendedColDef[] = [
      {
        field: "duid",
        headerName: "DUID",
        hide: !pageStore.showDebugInfo,
        suppressMovable: true,
        cellRenderer: DuidCellEditor,
        sortable: true,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        field: "order",
        headerName: "Order",
        hide: !pageStore.showDebugInfo,
        suppressMovable: true,
        cellRenderer: OrderCellEditor,
        sortable: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        field: dartboardProperty.duid as keyof Task,
        headerName: dartboardHeaderName,
        headerComponentParams: {
          property: dartboardProperty,
        },
        hide: !isAnyView.value || pageStore.isPublicView,
        cellRenderer: DartboardCellEditor,
        sortable: true,
        comparatorFn: (a: string, b: string) =>
          makeDartboardComparator(dataStore.getSpaceByDuid)(
            dataStore.getDartboardByDuid(a),
            dataStore.getDartboardByDuid(b)
          ),
        suppressHeaderMenuButton: true,
        minWidth: 100,
        maxWidth: 100,
        resizable: false,
        getSearchValue: (value: string) =>
          getPageDisplayName(dataStore.getDartboardByDuid(value), dataStore.getSpaceByDuid),
      },
    ];

    /* Custom properties */
    res.push(
      ...properties.value.map(({ property, config, values }) => {
        const isDefaultStatus = property.kind === PropertyKind.DEFAULT_STATUS;
        return {
          ...values,
          field: property.duid as keyof Task,
          headerName: property.title,
          suppressMovable: isDefaultStatus,
          lockPosition: config.lockPosition,
          headerComponentParams: {
            property,
          },
          cellRendererParams: {
            property,
            ...values.cellRendererParams,
          },
        };
      })
    );
    const comparator = makePropertyComparator(appStore.propertyOrderDuids);
    res.sort((a, b) => comparator(a.field, b.field));

    return res;
  });

  return { columnDefs, defaultColDef, titleColDef };
};

export default columns;
